.sashList {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  flex-wrap: nowrap;
  min-width: 200%;

  &_map {
    display: inline;
    border: 1px solid #e1e8ee;
  }
}
